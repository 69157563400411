import dot from 'dot-get';
import _ from 'lodash';

export default class Score {
  static getEolScores(server, categories) {
    const results = this.getEolPercentages(server.results);

    return categories.map((item) => {
      const scores = dot.get(results, item.key);
      const data = [scores.lca, scores.lcc, scores.slca, scores.criticality];
      return {
        name: item.label,
        data: data,
      };
    });
  }

  static getEolPercentages(results) {
    let data = {};

    const categories = Object.keys(results);
    const keys = Object.keys(results[categories[0]]);

    for (let i in keys) {
      dot.set(data, keys[i], {});
      for (let j in categories) {
        dot.set(data[keys[i]], categories[j], results[categories[j]][keys[i]]);
      }
    }

    for (let i in keys) data[keys[i]] = this.formatKeyScores(data[keys[i]]);

    for (let i in categories) {
      for (let j in keys) {
        results[categories[i]][keys[j]] = data[keys[j]][categories[i]]
      }
    }

    return results;
  }

  static formatKeyScores(data) {
    const values = Object.values(data);
    const max = _.max(values);

    Object.keys(data).forEach((key) => {
      data[key] = Math.round((data[key] / max) * 100);
    });

    return data;
  }

  static getServerScores(quantity, results) {
    let final = [];
    Object.keys(results).forEach((category) => {
      final[category] = results[category] * quantity;
    });
    return final;
  }

  // Order must match graph: LCA, LCC, SLCA, Criticality
  static formatResults(results) {
    return {
      lca: results.lca,
      lcc: results.lcc,
      slca: results.slca,
      criticality: results.criticality,
    };
  }

  static getRackScores(rack) {
    let results = [];

    for (let i in rack) {
      const rackRes = this.formatResults(rack[i].results);
      const scores = this.getServerScores(rack[i].quantity, rackRes);
      results.push(scores);
    }

    let chartData = {};

    for (let i in results) {
      Object.keys(results[i]).forEach((category) => {
        if (chartData[category]) chartData[category] += results[i][category];
        else chartData[category] = results[i][category];
      });
    }

    return chartData;
  }

  static getCompareScores(rack1, rack2) {
    let data1 = this.getRackScores(rack1);
    let data2 = this.getRackScores(rack2);

    let dc1 = {};
    let dc2 = {};

    const keys = Object.keys(data1);

    for (let i in keys) {
      const key = keys[i];

      if (data1[key] > data2[key]) {
        dc1[key] = 100;
        dc2[key] = Math.round((data2[key] / data1[key]) * 100);
      } else if (data2[key] > data1[key]) {
        dc2[key] = 100;
        dc1[key] = Math.round((data1[key] / data2[key]) * 100);
      } else {
        dc1[key] = dc2[key] = 100;
      }
    }

    return [
      {
        name: 'Data centre 1',
        data: Object.values(dc1),
      },
      {
        name: 'Data centre 2',
        data: Object.values(dc2),
      },
    ];
  }
}
